import React from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldArray } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { differenceInYears, isValid } from 'date-fns';
import { SEASON_CONFIG } from '../config/dates';

const calculateAge = (birthDate) => {
  if (!birthDate) return null;
  const date = new Date(birthDate);
  return isValid(date) ? differenceInYears(new Date(SEASON_CONFIG.SEASON_START), date) : null;
};

const needsSwimPermission = (birthDate) => {
  const age = calculateAge(birthDate);
  return age !== null && age >= 12 && age < 18;
};

export const HouseholdMembersSection = ({ values, handleChange, setFieldValue }) => {
  const handleDateChange = (index, newValue) => {
    // Only update if we have a valid date or null
    if (newValue === null || isValid(newValue)) {
      const formattedDate = newValue ? newValue.toISOString().split('T')[0] : null;
      setFieldValue(`familyMembers.${index}.birthdate`, formattedDate);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" sx={{ color: 'primary.main', mb: 1 }}>
          Household Members
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Please add any additional household members below
        </Typography>
      </Box>

      <Paper sx={{ p: 2, mb: 3, bgcolor: 'grey.50' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Main Member:
          </Typography>
          <Typography>
            {values.firstName} {values.lastName}
          </Typography>
        </Box>
      </Paper>

      <FieldArray name="familyMembers">
        {({ push, remove }) => (
          <Box>
            {values.familyMembers.map((member, index) => {
              const requiresPermission = needsSwimPermission(member.birthdate);
              const age = calculateAge(member.birthdate);
              
              return (
                <Paper key={member.id} sx={{ p: 2, mb: 3, border: 1, borderColor: 'grey.200' }}>
                  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                      fullWidth
                      name={`familyMembers.${index}.name`}
                      label="Full Name"
                      variant="outlined"
                      value={member.name}
                      onChange={handleChange}
                      size="small"
                    />
                    <IconButton 
                      onClick={() => remove(index)} 
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <DatePicker
                      label="Under 18? Please enter birthdate"
                      value={member.birthdate ? new Date(member.birthdate) : null}
                      onChange={(newValue) => handleDateChange(index, newValue)}
                      format="dd/MM/yyyy"
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          variant: "outlined",
                          placeholder: "DD/MM/YYYY"
                        }
                      }}
                    />
                  </Box>

                  {age !== null && (
                    <Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Age at start of season: {age} years
                      </Typography>
                      {age < 12 && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                          {member.name || 'This member'} must be accompanied by an adult at all times when swimming
                        </Alert>
                      )}
                    </Box>
                  )}

                  {requiresPermission && (
                    <Box sx={{ mt: 1 }}>
                      <Alert severity="info" sx={{ mb: 2 }}>
                        {member.name || 'This member'} will be between 12-18 at the start of the season. 
                        Please indicate if they have permission to swim unaccompanied.
                      </Alert>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={member.swimPermission || false}
                            onChange={(e) => {
                              setFieldValue(`familyMembers.${index}.swimPermission`, e.target.checked);
                            }}
                            name={`familyMembers.${index}.swimPermission`}
                            size="small"
                          />
                        }
                        label={
                          <Typography variant="body2">
                            I give permission for {member.name || 'this member'} to swim unaccompanied
                          </Typography>
                        }
                      />
                    </Box>
                  )}
                </Paper>
              );
            })}
            
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => push({
                id: Math.random(),
                name: "",
                birthdate: null,
                swimPermission: false,
              })}
              sx={{ mt: 1 }}
            >
              Add Household Member
            </Button>
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};