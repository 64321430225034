import React from 'react'
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'
import { UserProvider } from './context/UserContext';

import './style.css'
import Home from './views/home'
import Register from './views/registration'
import Donation from './views/donation'
import NotFound from './views/not-found'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RulesBook from './components/RulesBook';
import { Success, Failure } from './components/PaymentConfirmation';



const App = () => {
  return (  
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />  // Add component for root
          <Route path="/register" element={<Register />} />  // Add rgistration page
          <Route path="/donation" element={<Donation />} />  // Add rgistration page
          <Route path="*" element={<NotFound />} />  // Correct catch-all syntax
          <Route path="/rules" element={<RulesBook />} /> 
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
        </Routes>
      </Router>
    </UserProvider>
    </LocalizationProvider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
