import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import './RulesBook.css';  

const RulesBook = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const sections = [
    {
      title: "Administration and Safety Officers",
      content: `Administration Officers need no special qualifications but one hour training sessions are given as
      required. They have to check on admissions, administer minor first aid and must be over 18.

      The First Safety Officers patrol the pool side and enforce all aspects of water safety, they have to be good
      swimmers aged 18 or over, and hold either a current National Pool Lifeguard Qualification, RLSS
      Bronze Medallion Lifesaving award or CCSC Safety Officer qualification. Classes are run during the
      summer at Comberton pool for the CCSC qualifications.

      The Second Safety Officers assist the First Safety Officers in patrolling the pool side but do not
      require the same level of skills. They should be good swimmers over 18 (over 16 if holding a Bronze
      Medallion) and be validated by the club Chief Safety Officer at the training/refresher courses held
      each spring.`
    },
    {
      title: "Normal Operating Plan",
      content: [
        {
          subtitle: "1. Staffing",
          text: "There will be a minimum of one First Safety Officer, one Second Safety Officer and two Administrative persons for each swimming session."
        },
        {
          subtitle: "2. Authority",
          text: "The First Safety Officer on duty has overall responsibility for maintaining safety in the pool and has absolute authority, even to closing the pool if required."
        },
        {
          subtitle: "3. Maximum number of swimmers",
          text: "A maximum of 100 swimmers are allowed in the pool at any time; this will not be exceeded for any reason. Safety officers may restrict numbers below this level if they feel that safety is affected."
        },
        {
          subtitle: "4. Safety",
          text: "Nobody is allowed to open, use the pool or equipment without a First Safety Officer present on pool side. No child under the age of 8 years should be permitted to use the common changing room facilities unaccompanied. Photography is strictly prohibited in all areas of the pool."
        },
        {
          subtitle: "5. Whistle signals",
          text: `Both Safety Officers must have a whistle while on duty.
          - One short blast: To attract a swimmer's attention.
          - Two short blasts: For Safety Officers to attract each other's attention.
          - One long blast: The pool must be cleared IMMEDIATELY as an emergency may be in progress.`
        },
        {
          subtitle: "7. Balls, Flippers, Snorkels, Bricks and Face Masks",
          text: "These are not allowed. Approved swimming goggles only may be worn. A maximum of TWO soft footballs may be allowed in the pool at the discretion of the First Safety Officer and only when the bather load is low."
        },
        {
          subtitle: "8. Watches and Jewellery",
          text: "For safety reasons watches and wrist jewellery will NOT be worn by any swimmer. It is strongly recommended that earrings, rings and other jewellery are removed."
        },
        {
          subtitle: "9. Inflatable armbands and Floats",
          text: "No inflatable toys or equipment are allowed other than inflatable arm bands. Inflatable armbands are not allowed on swimmers' legs. No bather wearing inflatable armbands or using a buoyancy aid is allowed in the deep end of the Pool."
        },
        {
          subtitle: "10. Learner and Toddlers area",
          text: "When learners are present in the pool five metres of the shallow end will be roped off to make a learner area. Only learners, toddlers and those giving instructions are allowed in the learner area. Non swimmers or weak swimmers must be accompanied IN THE WATER at all times by a responsible person."
        },
        {
          subtitle: "11. Safety Officer recognition",
          text: "Both Safety Officers should wear the club provided sweat shirts or T-shirt, and dress suitable for a possible water rescue."
        },
        {
          subtitle: "12. Hygiene",
          text: "All swimmers are required to wear approved swim wear, no cut-off jeans, or trousers, are allowed in the water. Babies and small children must also wear a swim costume or pants. No one is permitted to swim/sunbathe nude or partially clothed."
        },
        {
          subtitle: "13. Length of duty",
          text: "No Safety Officer must work for more than two and a half hours without a break."
        },
        {
          subtitle: "14. Lack of Water Clarity",
          text: "In the event of very poor visibility the pool will be closed and not re-opened until full water clarity has been attained."
        },
        {
          subtitle: "15. General Rules",
          text: `NO smoking in the pool area.
          NO spitting, in the pool or pool area.
          NO litter may be left on the pool side.
          NO glass articles in the pool area.
          NO chewing gum or eating sweets in the pool.
          Refreshment may only be consumed in the designated refreshment area.`
        },
        {
          subtitle: "16. Membership Cards",
          text: "Membership cards are NOT transferable and will be withdrawn by the committee if misused."
        },
        {
          subtitle: "17. Unaccompanied Status",
          text: "Young members having \"unaccompanied status\" may not bring in young members under 12 years old, guests or non members."
        },
        {
          subtitle: "18. Regulations",
          text: "All members of the club should make themselves aware of the Normal Operating Plan and the Emergency Action Plan. Safety Officers should also read the Club Code of Safe Working Practice."
        },
        {
          subtitle: "19. Responsibility",
          text: "The Swimming Pool Committee does not accept any responsibility for loss or damage to any property left in the pool area or losses consequent upon such loss or damage, or for injury or death of any person except directly caused through the negligence of the Swimming Pool Committee."
        },
        {
          subtitle: "20. Guests",
          text: "Guests may not normally be admitted. An exception is made for occasional guests of member families who live more than 10 miles from Comberton; up to a maximum of four per visit and only when accompanied at all times by an adult member of the host family."
        },
        {
          subtitle: "21. Amendments",
          text: "No amendments to the Normal Operating Plan or Emergency Action Plan will be made other than by the committee of Comberton Community Swimming Club"
        }
      ]
    },
    {
      title: "Emergency Action Plans",
      content: [
        {
          subtitle: "Accident in Pool",
          text: `1) In the event of an emergency either Safety Officer will give one long blast of their whistle followed by a voice demand to clear the pool.
          2) The First Safety Officer will take charge and execute the rescue.
          3) The Second Safety Officer will ensure the pool is cleared and back up the rescue if requested by the First Safety Officer.
          4) One of the Admin Officers will stand by the telephone ready to call an ambulance if requested.`
        },
        {
          subtitle: "Fire Emergency",
          text: `1) Any member of the duty staff discovering or being informed of a fire should immediately clear the pool.
          2) One Admin person should call the Fire Brigade on instructions from the safety officers.
          3) Staff will open both ENTRY / EXIT gates and direct all persons out of the pool area.
          4) Admin persons should make a roll-call of all swimmers evacuated from the pool.`
        }
      ]
    },
    {
      title: "Pool Covers and Rollers",
      content: `The pool Thermal Covers are one of the most important pieces of equipment the club provides for
      the pool, and represent a considerable financial outlay.

      They maintain a higher water temperature than would be otherwise possible and make a
      considerable reduction in heating costs; they are however easily damaged unless handled carefully.

      Please follow these simple directions:-
      a) Make sure the roller locating bolts are fully up and hooked in position before moving the rollers.
      b) Move the rollers slowly and have one person at each end.
      c) When winding covers from the pool do it slowly with the BLUE side outermost and keeping covers square onto the roller.
      d) When replacing the covers onto the pool first remove the two sets of pool steps, take the pull rope to the opposite end of the pool, THEN SLOWLY pull the cover off the roller.`
    },
    {
      title: "General Notes for Duty Officers",
      content: [
        {
          subtitle: "Starting a session",
          text: `1. The First Safety Officer will normally collect the pool keys from the Comberton Leisure Centre.
          2. All Officers should be at the pool at least ten minutes before the start of a session.
          3. Before opening the pool, inspect:
             a) Whistles are available
             b) Rubber bands are ready 25 of each, 5 colours
             c) Lane marker and pool divider ropes are available
             d) Changing rooms are open and floors and toilets are clean
             e) High chair is in place, Ropes & Poles are hung in place
             f) Phone works
             g) First aid kit and thermal blanket are present
             h) Pool covers are off`
        },
        {
          subtitle: "Finishing a session",
          text: `1. At the end of the final session:
             a) Remove steps and replace pool covers
             b) Clear excess water from changing room floors and clean toilets
             c) Stand duck boards on edge
             d) Turn off all lights, turn off water and power switch on shower
             e) Lock changing rooms, admin office and gate
             f) Return keys to Comberton Leisure Centre`
        }
      ]
    },
    {
      title: "Working Notes for Administrative Officers",
      content: [
        {
          subtitle: "Admissions",
          text: `Only admit swimmers when Safety Officers say they are ready at the start of a session.
          1) Retain membership card and issue a rubber band to each swimmer. Check names and age of children against card.
          2) Young members having "unaccompanied status" may not bring in young members under 12 years old.
          3) Refuse admission to anyone with bad cuts, sores, plasters and anyone eating chewing or carrying glassware.
          4) Last admissions are 15 minutes before the end of session.`
        },
        {
          subtitle: "Procedure for issuing bands",
          text: `1) The maximum number of swimmers allowed in the pool is 100.
          2) Issue one set of coloured bands to the first 25 swimmers, a second colour to the next 25 until there are 100 swimmers.
          3) Advise the First Safety Officer when you have issued the third colour (75 swimmers).
          4) When 100 bands have been issued any bands returned of those colours should NOT be re-issued.
          5) In hot weather there may still be a queue after the maximum number has been reached. After the first half-hour the safety officer will call out the first 25 swimmers.`
        }
      ]
    },
    {
      title: "Constitution of the Club",
      content: [
        {
          subtitle: "Name and Eligibility",
          text: `The Club shall be called "Comberton Community Swimming Club". Membership is open to all families in the catchment area of the Village College: Toft, Eversdens, Kingston, Bourn, Harlton, Haslingfield, Coton, Grantchester, Barton, Longstowe, Hardwick, Caxton, Caldecote, Cambourn, Newnham etc.: members of the Community Association; families of children attending the Village College.`
        },
        {
          subtitle: "Officers and Committee",
          text: `The Committee shall be responsible for the day-to-day running of the club, and shall have the following honorary officers: Chairman; Vice Chairman; Treasurer; Secretary; Membership Secretary; Minutes Secretary; Rota Secretary; Housekeeper; Chief Safety Officer; and up to 20 Committee members.`
        },
        {
          subtitle: "Duties of Officers",
          text: `Chairman: The Chairman or in his absence the Vice-Chairman shall preside over all the meetings of the club.
          
          Secretary: The secretary shall be responsible for correspondence of the club.
          
          Treasurer: The treasurer shall keep such books, accounts and records as the members shall require, and prepare and submit at the Annual General Meeting an audited statement of the accounts.
          
          Chief Safety Officer: The Chief Safety Officer shall organise and carry out training for Club Safety Officers and validate second safety officers at training sessions held each year.`
        }
      ]
    }
  ];

  const Section = ({ title, content, isExpanded, onClick }) => (
    <div className="section-container">
      <button className="section-header" onClick={onClick} aria-expanded={isExpanded}>
        <h2 className="section-title">{title}</h2>
        <span className="section-icon">
          {isExpanded ? (
            <ChevronDown size={20} />
          ) : (
            <ChevronRight size={20} />
          )}
        </span>
      </button>
      
      {isExpanded && (
        <div className="section-content">
          {Array.isArray(content) ? (
            <div>
              {content.map((item, i) => (
                <div key={i} className="subsection">
                  <h3>{item.subtitle}</h3>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>{content}</p>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="rules-container">
      <div className="rules-header">
        <h1>CCSC Rules Book</h1>
        <p>Membership Policies, Rules & Operating Procedures</p>
      </div>

      {sections.map((section, index) => (
        <Section
          key={index}
          title={section.title}
          content={section.content}
          isExpanded={expandedSection === index}
          onClick={() => setExpandedSection(expandedSection === index ? null : index)}
        />
      ))}
    </div>
  );
};

export default RulesBook;