import React from 'react';
import { SAFETY_OFFICER_DATES } from '../config/dates';
import { Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

const TrainingWeekendSelector = ({ values, handleChange }) => {
  // Track checked state in component
  const [checkedDates, setCheckedDates] = React.useState({});

  // Handle checkbox changes
  const handleDateChange = (date) => (event) => {
    const newCheckedState = {
      ...checkedDates,
      [date]: event.target.checked
    };
    setCheckedDates(newCheckedState);
    
    // Call the parent's handleChange with a synthesized event
    handleChange({
      target: {
        name: `dutydate${date.replace(/\s+/g, '')}`,
        value: event.target.checked,
        type: 'checkbox',
        checked: event.target.checked
      }
    });
  };

  const TRAINING_DATES = SAFETY_OFFICER_DATES.FIRST_OFFICER.TRAINING_DATES;

  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <FormLabel component="legend">
        You only need to attend one weekend but please select all weekends on which you are available for training. :
      </FormLabel>
      <FormGroup>
        {TRAINING_DATES.map((date) => (
          <FormControlLabel
            key={date}
            control={
              <Checkbox
                checked={checkedDates[date] || false}
                onChange={handleDateChange(date)}
                name={`dutydate${date.replace(/\s+/g, '')}`}
              />
            }
            label={date}
          />
        ))}
      </FormGroup>
      <FormHelperText>You must be able to attend both days of the same weekend</FormHelperText>
    </FormControl>
  );
};

export default TrainingWeekendSelector;