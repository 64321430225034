import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const PaymentConfirmation = ({ status = 'success' }) => {
  const navigate = useNavigate();
  const isSuccess = status === 'success';

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <StyledPaper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ py: 4 }}>
          {isSuccess ? (
            <CheckCircleIcon 
              sx={{ 
                fontSize: 64,
                color: 'success.main',
                mb: 3
              }} 
            />
          ) : (
            <ErrorIcon 
              sx={{ 
                fontSize: 64,
                color: 'error.main',
                mb: 3
              }} 
            />
          )}
          
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 500 }}>
            {isSuccess ? 'Payment Successful!' : 'Payment Failed'}
          </Typography>
          
          <Typography variant="body1" sx={{ mt: 2, mb: 2, maxWidth: 600 }}>
            {isSuccess ? (
              'Thank you for registering with Comberton Community Swimming Club. Your payment has been processed successfully.'
            ) : (
              "We're sorry, but your payment could not be processed at this time."
            )}
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 4, maxWidth: 600 }}>
            {isSuccess ? (
              'Our membership secretary will be in touch shortly with further details about your membership.'
            ) : (
              <>
                Please try again or contact us at{' '}
                <StyledLink href="mailto:contact@comberton-pool.org.uk">
                  contact@comberton-pool.org.uk
                </StyledLink>
                {' '}if you continue to experience issues.
              </>
            )}
          </Typography>

          <Box sx={{ mt: 2 }}>
            {!isSuccess && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/register')}
                size="large"
                sx={{ mr: 2 }}
              >
                Try Again
              </Button>
            )}
            <Button
              variant={isSuccess ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => navigate('/')}
              size="large"
            >
              Return to Home
            </Button>
          </Box>
        </Box>
      </StyledPaper>
    </Container>
  );
};

// Export named components that use the base component
export const Success = () => <PaymentConfirmation status="success" />;
export const Failure = () => <PaymentConfirmation status="failure" />;