// src/config/dates.js

// Season dates
export const SEASON_CONFIG = {
  SEASON_START: '2025-05-10',
  SEASON_END: '2025-09-07',
  EVENING_TIMES_CHANGE_DATE: '2025-08-14',
  REGISTRATION_VERSION: 'v2.0',
  REGISTRATION_YEAR: '2025'
};

// Safety officer training dates and deadlines
export const SAFETY_OFFICER_DATES = {
  FIRST_OFFICER: {
    TRAINING_YEAR: '2025',
    TRAINING_DATES: ['1 & 2nd of March 25', '15 & 16th of March 25'],
    REGISTRATION_DEADLINE: '2025-03-14',
    DISCOUNT_PERCENTAGE: 50
  },
  SECOND_OFFICER: {
    THEORY_SESSIONS: [
      { DATE: '4 March 25', TIME: '7:30-9:30pm' },
      { DATE: '11 March 25', TIME: '7:30-9:30pm' }
    ],
    POOL_SESSIONS: [
      { DATE: '6 April 25', TIME: '11:15-12:15am' },
      { DATE: '13 April 25', TIME: '11:15-12:15am' }
    ],
    REGISTRATION_DEADLINE: '2025-04-24'
  }
};

// Opening times
export const OPENING_TIMES = {
  REGULAR: {
    EVENING: {
      START: '18:30',
      END: '20:30',
      LATE_SEASON_END: '19:45' // After August 14
    },
    WEEKEND: {
      START: '15:00',
      END: '17:00'
    }
  },
  SCHOOL_HOLIDAYS: {
    START: '14:30',
    END: '16:30'
  }
};

// Utility functions for deadline checks
export const isFirstSafetyOfficerDeadlinePassed = () => {
  const deadline = new Date(SAFETY_OFFICER_DATES.FIRST_OFFICER.REGISTRATION_DEADLINE);
  return new Date() > deadline;
};

export const isSecondSafetyOfficerDeadlinePassed = () => {
  const deadline = new Date(SAFETY_OFFICER_DATES.SECOND_OFFICER.REGISTRATION_DEADLINE);
  return new Date() > deadline;
};

// Utility function to get default duty type based on deadlines
export const getDefaultDutyType = (currentValue) => {
  const firstDeadlinePassed = isFirstSafetyOfficerDeadlinePassed();
  const secondDeadlinePassed = isSecondSafetyOfficerDeadlinePassed();

  if (currentValue === '1st Safety Officer' && firstDeadlinePassed) {
    return 'Administrator';
  }
  if (currentValue === '2nd Safety Officer' && secondDeadlinePassed) {
    return 'Administrator';
  }
  return currentValue || 'Administrator';
};

// Function to format time ranges for display
export const formatTimeRange = (start, end) => {
  return `${start}-${end}`;
};

// Function to get current session end time based on date
export const getSessionEndTime = (date) => {
  const changeDate = new Date(SEASON_CONFIG.EVENING_TIMES_CHANGE_DATE);
  const checkDate = new Date(date);
  
  return checkDate > changeDate 
    ? OPENING_TIMES.REGULAR.EVENING.LATE_SEASON_END 
    : OPENING_TIMES.REGULAR.EVENING.END;
};